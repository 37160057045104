#Cam_overlay {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 94px;
  height: 100%;
  overflow: hidden;
}
.side-buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 15px 30px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  display: grid;
  & > * {
    margin: auto;
    & + * {
      margin-top: 1rem;
    }
  }
}
.btn {
  &-circle {
    border-radius: 50% !important;
    width: 4rem;
    height: 4rem;
    padding: 2px 0 0 !important;
    & > i {
      font-size: 2rem;
    }
  }
  &-sm {
    width: 3rem;
    height: 3rem;
    & > i {
      font-size: 1.5rem;
    }
  }
  &-confirm {
    background: rgba(39, 208, 137, 0.7) !important;
    border: 2px solid rgba(255, 255, 255, 1) !important;
    & > i {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  &-cancel {
    background: rgba(46, 25, 62, 0.7) !important;
    border: 2px solid rgba(255, 255, 255, 1) !important;
    & > i {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  &-addon {
    background: rgba(255, 255, 255, 0.7) !important;
    border: 2px solid rgba(255, 255, 255, 1) !important;
    & > i {
      color: rgba(0, 0, 0, 0.7);
    }
  }
  &-disabled {
    opacity: 0.5 !important;
  }
}
.overlay-ui {
  outline: #000 solid 10000px;
}
.qr-code-scanner {
  position: absolute;
  inset: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  video {
    position: relative;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    &:after {
      display: none;
    }
  }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   inset: 0;
  //   background-image: url(../images/);
  // }
}
.scan-qrcode-place-holder {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}
