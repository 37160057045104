.user-inspect {
  z-index: 201;
  position: relative;
  background: #fff !important;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  .inspect-data {
    .confirm-damage {
      position: fixed;
      bottom: 20px;
      right: 20px;
    }
    .list-damage {
      line-height: 1;
      max-height: 180px;
      overflow-y: auto;
      position: absolute;
      top: 20px;
      left: 0;
      border-radius: 0;
      & > li {
        padding: 0;
        text-align: left;
        a {
          white-space: nowrap;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          display: block;
          padding: 0.5rem;
        }
      }
    }
    .damage-view {
      position: fixed;
      top: 20px;
      right: 20px;
      button {
        width: 40px !important;
        line-height: 0 !important;
        font-size: 16px !important;
      }
    }
    .img-full {
      // height: 100vh;
      // height: calc(var(--vh, 1vh) * 100);
      // height: 100%;
      .damage-point {
        position: absolute;
        line-height: 1;
        text-align: center;
        opacity: 0.7;
        margin-left: -11px;
        margin-top: -11px;
        z-index: 999;
        &.left {
          .badge {
            position: absolute;
            right: 100%;
            top: 0;
          }
        }
        &.right {
          .badge {
            position: absolute;
            left: 100%;
            top: 0;
          }
        }
        & > i {
          color: red;
          line-height: 1;
          font-size: 22px;
        }
        .badge-danger {
          font-size: 90% !important;
          top: -3px;
          position: relative;
          background: #fff !important;
          color: #000 !important;
          opacity: 0.8;
        }
      }
      .imageHolder {
        position: absolute;
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          opacity: 0;
        }
      }
      svg {
        // width: 100%;
        // height: 100%;
        // max-width: 100%;
        // max-height: 100%;
        // opacity: 1;
        opacity: 0;
      }
    }
    .img-thumb {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 1000;
      &.off {
        display: none;
      }
      .group-thumb {
        position: absolute;
        top: 40%;
        text-align: center;
        width: 100%;
        span {
          color: #000;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
  .data {
    margin-top: 55px;
    .title {
      text-align: center;
      margin: 15px 0;
    }
  }
}
.ant-modal-wrap {
  .ant-modal {
    top: 40px;
    .ant-modal-content {
      .ant-modal-confirm-body {
        .ant-modal-confirm-content {
          max-height: 195px;
          //overflow-y: scroll;
          .select-damage {
            margin: 0;
            padding: 0;
            .badge {
              font-size: 15px;
            }
            .react-select__menu {
              //position: absolute;
              overflow-y: scroll;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
.user-inpsect-modal {
  .ant-modal {
    top: 50px;
    .ant-modal-body {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}
.wrapp-user-addamages {
  position: relative;
  overflow: hidden;
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 30%;
    background-color: $primary-color-violet;
    z-index: 2;
  }
  .text-overlay-user-addamages {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    color: #fff;
    padding: 0 25px;
    h2 {
      color: #fff;
      margin-bottom: unset;
    }
    .des {
      color: $primary-color-gray;
      font-size: 13px;
    }
  }
}
.img-user-addamages {
  img {
    border-radius: 10px;
  }
}
.overlay-title {
  text-transform: uppercase;
  font-weight: 600;
}

.user-add-damages {
  .ant-modal-body {
    padding: 15px;
  }
}
.user-add-damages-selectbox {
  border: 1px solid #ddd;
  background: transparent;
  font-size: 12px;
  padding: 5px 4px;
  border-radius: 5px;
}
