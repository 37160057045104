/* --- Errors --- */

#error {
  z-index: 3;
  background: #fff;
  color: #212529;
  min-height: 100vh;
  z-index: 30;
  position: relative;
  i {
    font-size: 22px;
    color: #9398a0;
  }
  .content {
    text-align: center;
    .photo {
      position: relative;
      margin: 0 auto 40px auto;
      width: 360px;
      img {
        width: 100%;
      }
    }
    h1 {
      margin: 0 0 15px 0;
      padding: 0;
      font-size: 38px;
      text-transform: uppercase;
      font-weight: 900 !important;
    }
    h5 {
      margin: 0 0 2px 0;
      padding: 0;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    p {
      padding: 5px 10px;
    }
    .form {
      position: relative;
      margin: auto;
      padding: 0;
      max-width: 650px;
      width: 100%;
      .input {
        position: relative;
        width: 100%;
        margin: 15px auto;
        padding: 8px 15px;
        border: 2px solid #e1e4ea;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        input {
          width: 100%;
          border: none;
          text-align: left;
        }
        i {
          position: absolute;
          top: 9px;
          left: 13px;
          margin: 0;
          padding: 0;
          font-size: 22px;
          color: #9398a0;
        }
      }
      button {
        border: none;
        position: relative;
        padding: 19px;
        width: 250px;
        // margin: 30px auto 0 auto;
        color: #fff;
        background: #27d089;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        -webkit-border-radius: 400px;
        -moz-border-radius: 400px;
        border-radius: 400px;
        text-align: center;
        font-size: 13px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        -webkit-box-shadow: 0px 10px 28px -22px rgba(0, 0, 0, 0.43);
        -moz-box-shadow: 0px 10px 28px -22px rgba(0, 0, 0, 0.43);
        box-shadow: 0px 10px 28px -22px rgba(0, 0, 0, 0.43);
        &:hover {
          background: #2edb90;
        }
      }
    }
  }
}

// #error .content .exit {
//   position: relative;
//   display: inline-block;
//   padding: 0 5px;
//   font-family: 'Montserrat', sans-serif;
//   font-weight: 600;
//   font-size: 14px;
//   letter-spacing: 1px;
//   margin: 10px 0 0 0;
// }

// #error .content .exit .line {
//   position: absolute;
//   bottom: 1px;
//   left: 0;
//   right: 0;
//   height: 8px;
//   background: #e3e6ea;
//   z-index: -1;
// }
