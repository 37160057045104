// fonts
@font-face {
  font-family: "Montserrat" !important;
  src: url("./Montserrat/Montserrat-Black.ttf");
  src: url("./Montserrat/Montserrat-Bold.ttf");
  src: url("./Montserrat/Montserrat-Italic.ttf");
  src: url("./Montserrat/Montserrat-Light.ttf");
  src: url("./Montserrat/Montserrat-Medium.ttf");
  src: url("./Montserrat/Montserrat-Regular.ttf");
}

// bootstrap
// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";
// @import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/reboot";

// app styles\
//user Inspect
@import "./internal_variables";
@import "./userInspect";
//
@import "./general";
@import "./animation";
@import "./responsive";
@import "./spinnerIcon";
@import "./components/lite";
@import "./components/home";
@import "./components/header";
@import "./components/damage";
@import "./components/survey";
@import "./components/report";
@import "./components/retake";
@import "./components/rotate";
@import "./components/navbar";
@import "./components/plusBtn";
@import "./components/notmobile";
@import "./components/vinDecode";
@import "./components/modalDams";
@import "./components/oDomDecode";
@import "./components/disclosure";
@import "./components/cam_overlay";
@import "./components/countdownTimer";
@import "./components/waitingProccess";
@import "./components/retake_gallery";
@import "./components/barcodeReader";
@import "./components/reuploadMisisg";
@import "./components/deepLink";
