#interactive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  video {
    width: 100%;
    height: 100%;
    // object-fit: none !important;
  }
  canvas {
    display: none;
  }
}
.bar-overlay-landscape {
  top: 0px;
  left: 0px;
  z-index: 1;
  box-sizing: border-box;
  border: 90px solid rgba(0, 0, 0, 0.3);
  box-shadow: $primary-color-green-rgba 0px 0px 0px 5px inset;
  position: absolute;
  width: 100%;
  height: 100%;
}
.bar-overlay-portrait {
  top: 0px;
  left: 0px;
  z-index: 1;
  box-sizing: border-box;
  border: 80px solid rgba(0, 0, 0, 0.3);
  box-shadow: $primary-color-green-rgba 0px 0px 0px 5px inset;
  position: absolute;
  width: 100%;
  height: calc(100%);
  // transform: rotate(90deg);
  border-left-width: 120px;
  border-right-width: 120px;
  border-top-width: 80px;
  border-bottom-width: 80px;
}
.qr-component,
.bar-component,
.qr-component-portrait {
  position: unset !important;
  .qr-logo-custom {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    .wrapp-logo-custom {
      max-width: 150px;
      width: 100%;
      margin: auto;
      padding: 20px;
      img {
        max-width: 100%;
      }
    }
  }
}

.bar-component {
  .scanning::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 150px;
    display: flex;
    background-image: linear-gradient(
      to right,
      rgba(170, 184, 190, 0),
      rgba(170, 184, 190, 0.8)
    );
    animation: scanning 4s linear infinite;
    align-items: center;
    justify-content: center;
    margin: auto 0;
  }
  .scanning-portrait::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 5px;
    display: flex;
    background-image: linear-gradient(
      to right,
      rgba(170, 184, 190, 0),
      rgba(170, 184, 190, 0.8)
    );
    animation: scanning-portrait 4s linear infinite;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
.btn-qrcode {
  transition: all 0.3s ease-in-out;
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .wrap-btn-qr-code {
    padding: 15px;
    .btn-qrcode-cancel {
      color: rgba(0, 0, 0, 0.7);
      background-color: rgba(255, 255, 255, 0.7) !important;
      border: 3px solid white;
      position: relative;
      &:after {
        content: "";
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 2px solid $primary-color-violet;
        border-radius: 50%;
      }
    }
  }
}
.qr-component-portrait {
  .qr-custom {
    section {
      position: unset !important;
      div {
        border: 60px solid rgba(0, 0, 0, 0.3) !important;
        box-shadow: $primary-color-green-rgba 0px 0px 0px 5px inset !important;
        border-top-width: 210px !important;
        border-bottom-width: 210px !important;
      }
    }
  }
}
.qr-component {
  .qr-custom {
    section {
      position: unset !important;
      padding-top: unset !important;
      div {
        border: 80px solid rgba(0, 0, 0, 0.3) !important;
        box-shadow: $primary-color-green-rgba 0px 0px 0px 5px inset !important;
        border-left-width: 250px !important;
        border-right-width: 250px !important;
      }
    }
  }
}
.animation-loader {
  animation: rotate-s-loader 1s linear infinite;
}
