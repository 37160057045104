.dams-modal-style {
	position: relative;
	.wrapp {
		select {
			outline: none;
			width: 200px;
			padding: 5px 20px;
			border: 1px solid #f2f2f2;
			border-radius: 20px;
			background: #fff;
			color: gray;
		}
	}
	// img {
	// 	width: 100%;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// }
}
