.odometer-decode {
  ::-webkit-input-placeholder {
    /* Edge */
    color: #d2d2d2;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d2d2d2;
  }
  ::placeholder {
    color: #d2d2d2;
  }
  input {
    color: inherit;
    text-transform: unset !important;
  }
  .error-mess {
    display: block;
    color: red;
    font-size: 14px;
  }
  .select-custom {
    select {
      outline: none;
      width: 200px;
      padding: 7px 20px;
      border: 1px solid #f2f2f2;
      // -webkit-border-radius: 40px;
      // border-radius: 20px;
      background: #fff;
      color: #d2d2d2;
      font-size: 14px;
      height: 35px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
    }
    .ant-select-selection {
      border-radius: 20px;
      height: 36px;
      outline: none;
      &:hover {
        border-color: unset !important;
      }
    }
    .ant-select-open .ant-select-selection {
      border-color: unset !important;
    }
    .ant-select-selection__rendered {
      line-height: 35px;
    }
    .ant-select-selection__placeholder {
      text-align: center;
    }
  }
}

.select-custom-remove-border {
  select {
    border: 0px;
    outline: 0px;
  }
}
