.btn {
  font-weight: 700 !important;
  text-transform: uppercase;
  &-confirm {
    background: #27d089 !important;
    color: #fff !important;
  }
}
.modal-survey {
  .ant-modal {
    min-width: 80% !important;
  }
  .ant-modal-body {
    font-family: "Montserrat", sans-serif;
    p {
      .right-badge {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }
}

.modal-message {
  .ant-modal {
    min-width: 40% !important;
  }
  .ant-modal-body {
    font-family: "Montserrat", sans-serif;
    p {
      .right-badge {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }
}
.paragraph {
  font-size: 90%;
}
