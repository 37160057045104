.missing-reupload-wrapper {
  position: relative;
  // padding: 10px 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 15px;
  .grid-container {
    // display: grid;
    // column-gap: 10px;
    // row-gap: 15px;
    // justify-items: center;
    // gap: 8px;
    .missing-upload-item {
      position: relative;
      color: #fff;
      font-size: 12px;
      // max-width: 230px;
      width: 100%;
      transition: all 0.5s ease-in-out;
      // max-height: 100px;
      height: 100%;
      background-color: #000;
      border-radius: 3px;
      transition: all 0.5s ease;
      .contents,
      .contents-custom {
        padding: 10px 10px 5px 10px;
        display: flex;
        justify-content: flex-start;
        transition: all 0.5s ease-in-out;
        .content-center {
          margin-left: 15px !important;
          text-align: left;
          transition: all 0.5s ease-in-out;
          .label-name {
            font-weight: bold;
            white-space: nowrap;
            font-size: 16px;
          }
          .status {
            span {
              transition: all 0.5s ease-in-out;
            }
            .default {
              text-transform: capitalize;
            }
          }
        }
        // .item {
        //   margin: auto 0;
        // }
        .btn-reupload {
          // margin: auto 0;
          .wrap-icon {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            line-height: 35px;
            color: #fff;
            font-size: 30px;
          }
          .wrap-icon-violet {
            color: $primary-color-violet-rgba;
          }
          .wrap-icon-green {
            color: $primary-color-green;
          }
        }
      }
      .finish {
        color: $primary-color-green;
        text-transform: uppercase;
        transition: all 0.5s ease-in-out;
      }
    }
    .bg-default,
    .done {
      bottom: 0;
      width: 100%;
      text-align: right;
      padding: 0 5px;
      font-weight: 600;
      font-size: 11px;
      transition: all 0.5s ease-in-out;
      // border-radius: 10px;
      .title,
      .sent {
        color: rgba($color: $primary-color-violet, $alpha: 1);
        text-transform: uppercase;
        // font-weight: 600;
      }
    }
    .bg-done {
      // background-color: $primary-color-green !important;
      span {
        color: #fff !important;
      }
    }
    // .bg-default {
    //   // background-color: rgba($color: $primary-color-gray, $alpha: 0.3);
    // }
    .default {
      color: #fff;
    }
    .transferring {
      color: $primary-color-gray;
    }
    .sent {
      color: $primary-color-green;
    }
    .bg-transferring {
      // background-color: rgba($color: $primary-color-violet, $alpha: 0.5);
      color: #fff !important;
    }
    .bg-default-overlay {
      background-color: red;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
    .text-over {
      position: absolute;
      bottom: -3px;
      right: 0;
      z-index: 1;
      .wrapp-text-over {
        display: flex;
        justify-content: flex-end;
        padding: 0 5px;
        color: rgba($color: #000000, $alpha: 0.7);
        .status-caption {
          text-transform: uppercase;
          margin-right: 10px;
          transition: all 0.5s ease-in-out;
          font-weight: 600;
        }
        .default-bottom-caption {
          // color: rgba($color: #000000, $alpha: 0.7);
          transition: all 0.5s ease-in-out;
          font-weight: 600;
        }
        .transferring-bottom-caption,
        .sent-bottom-caption {
          color: #fff;
          font-weight: 600;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
}
.reupload-caption {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  max-width: 550px;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.custom-progressbar {
  .ant-progress-bg {
    height: 18px !important;
    border-radius: unset !important;
  }
  .ant-progress-inner {
    border-radius: unset !important;
    margin-top: 20px !important;
  }
}
.custom-progressbar-isportrait {
  .ant-progress-inner {
    margin-top: 15px !important;
  }
}

.wrapper-process {
  position: relative;
}
.container-process {
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  color: #fff;
  .title-caption {
    width: 30%;
    display: block;
    margin: auto;
  }
  .wrapp-uploads {
    position: relative;
    overflow-y: scroll;
    max-height: 220px;
    height: 100%;
    width: 70%;
    .items {
      display: flex;
      flex-wrap: wrap;
      transition: all 0.5s ease;
      .item {
        margin: 3px;
        transition: all 0.5s ease;
      }
    }
  }
}
.position-label {
  text-transform: capitalize;
  margin-left: 12px;
  white-space: nowrap;
}
