// #confirmComplete{
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 998;
//     background: rgba(0, 0, 0, 0.85);
// }

// processing
.wrapp-inspection {
  background: white;
  display: flex;
  justify-content: center;
  right: 0;
  left: 0;
  height: 100vh;
  height: calc(100vh);
  color: black;
  font-size: 26px;
  font-weight: bold;
  .inspection-countdown {
    position: relative;
  }
  span.timer-count {
    font-size: 48px;
    margin: 0px 10px;
    letter-spacing: 4px;
    font-weight: 800;
  }
  span.exp-text {
    opacity: 0.5;
  }
}
.btn-notify {
  position: absolute;
  bottom: 0;
}

.pulse span.inspection-title {
  letter-spacing: 2px;
  font-weight: 900;
  font-size: 27px;
  font-style: oblique;
  text-transform: uppercase;
  //   animation: pulse 1s infinite;
  //   animation-timing-function: linear;
}

.circle-countDown {
  position: absolute;
  top: 31px;
  right: 135px;
  display: block;
  z-index: 100;
  color: #fff;
  font-weight: 700;
  #countdown {
    // border: 1.5px solid #27d089;
    border-radius: 50%;
    position: relative;
    margin: auto;
    height: 26px;
    width: 26px;
    text-align: center;
    #countdown-number {
      color: white;
      display: flex;
      line-height: 22px;
      font-size: 12px;
      justify-content: center;
      align-items: center;
    }
    svg {
      position: absolute;
      top: -9px;
      right: -8px;
      width: 40px;
      height: 40px;
      transform: rotateY(-180deg) rotateZ(-90deg);
      circle {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 2px;
        stroke: white;
        fill: none;
        // animation: countdown 6s linear forwards;
      }
    }
    .site-badge-count-5 .ant-badge-count {
      background-color: #27d089;
      color: #fff;
      box-shadow: 0 0 0 1px #27d089 inset;
      min-width: 25px;
      height: 25px;
      border-radius: 15px;
      line-height: 25px;
    }
    span.site-badge-count-5.ant-badge.ant-badge-not-a-wrapper {
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }
}
@keyframes countdown {
  from {
    stroke-dashoffset: 10px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}
