#loader_lite {
  position: relative;
  &.has-address-bar {
    margin-top: 45px !important;
  }
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 100;
  // height: 100vh;
  // // height: calc(var(--vh, 1vh) * 100);
  // background: black;
  // // height: calc(var(--vh, 1vh) * 100 +30px);
  // overflow: hidden;
  // display: none;
  .wrapp-btn-custom {
    position: relative;
    text-align: center;
    max-width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .btn-style {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      // max-width: 450px;
      z-index: 11;
    }
  }
  .pave-logo-ddc {
    display: flex;
    margin: auto;
    height: calc(100vh);
    position: relative;
    // top: -40px;
    .wrapp-ddc-component {
      width: 100%;
      margin: auto;
      .wrapp-img-ddc {
        max-width: 250px;
        margin: 20px auto 10px auto;
      }
      .btn-ddc-style {
        margin: 50px auto 10px auto;
        max-width: 480px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        .ddc-style {
          margin-left: 35px;
          text-transform: uppercase;
          border: 2px solid #fff;
          padding: 20px 30px;
          box-shadow: 0 0 15px #27d089;
          text-shadow: 0 0 15px #27d089;
          // width: 100px;
          // height: 100px;
          transition: 0.6s opacity ease-in-out;
          border-radius: 3px;
          &:hover {
            opacity: 1;
            transition: 0.6s opacity ease-in-out;
            // filter: blur(1px);
            // background: #27d089;
            background: linear-gradient(
              60deg,
              #27d089,
              #27d089,
              #ef4e7b,
              #a166ab,
              #5073b8,
              #1098ad,
              #07b39b,
              #6fba82
            );
            span {
              color: #fff;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:nth-child(2) {
            padding: 20px 38px;
          }
          span {
            // line-height: 50px;
            font-weight: bold;
            color: #27d089;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
#lite_progress {
  .ant-progress-circle .ant-progress-text {
    color: #fff;
    // color: rebeccapurple;
    font-weight: bold;
    line-height: 2;
  }
}
