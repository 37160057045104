/* --- Alert --- */

#alerts {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000002;
  background: rgba(0, 0, 0, 1);
  .centerAlert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 500px;
    text-align: center;
  }
  .retake-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    right: 0;
  }
}

#alerts .text {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // max-width: 500px;
  // margin: -70px 0 0 -150px;
  text-align: center;
}

#alerts .text h2 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 22px;
  text-transform: uppercase;
}

#alerts .text p {
  font-size: 13px;
  color: #9099a5;
  margin: 5px 0 20px 0;
}

#alerts .text h5 {
  color: #9099a5;
  font-size: 11px;
  margin: 0;
  font-weight: 300;
  letter-spacing: 2px;
}

#alerts .text h5 strong {
  font-weight: 900;
  color: #27d089;
}

#alerts .text i {
  color: #e03c3c;
  font-size: 22px;
  margin: 0 0 20px 0;
}

#alerts .text .button {
  position: relative;
  display: inline-block;
  padding: 0 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  margin: 10px 0 0 0;
  color: #fff;
}

#alerts .text .button .line {
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 7px;
  background: #424447;
  z-index: -1;
}

#alerts.retake {
  display: block;
  z-index: 10000000;
}
#alerts.retake span {
  font-weight: bold;
  color: #fff;
}
#alerts.retake h2 {
  font-size: 18px;
}
