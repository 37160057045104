/* --- Damage --- */

section.i-damage {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
img.i-image {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
#damage {
  display: block;
  margin: 0;
  padding: 90px 0px 60px 0px;
  background: #fff;
  color: black;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

#damage .notice {
  text-align: center;
  padding: 30px 0 100px 0;
}

#damage .notice h5 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}

#damage .loading {
  position: fixed;
  display: none;
  text-align: center;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 70px 30px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  background: #fff;
  overflow: scroll;
}

#damage .under {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 170px;
  background: #f4f5f7;
}

#damage .vehicle {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 3;
}

#damage .vehicle .photo {
  position: relative;
  float: left;
  width: 47%;
  // height: 235px;
}

#damage .vehicle .photo img {
  width: 100%;
  height: 100%;
}

#damage .vehicle .photo .background {
  position: absolute;
  left: 0%;
  right: 0;
  background: #f2f3f4;
  bottom: 0;
  z-index: -1;
  top: 100px;
}

#damage .vehicle .photo .overlay {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 3;
  -webkit-box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
}

#damage .vehicle .photo .count {
  position: absolute;
  bottom: 6px;
  right: 10px;
  padding: 5px 7px;
  font-size: 11px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  z-index: 10;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.7);
}

#damage .vehicle .photo .count i {
  padding: 0 1.5px 0 0;
  font-size: 18px;
  vertical-align: top;
  opacity: 0.7;
  display: inline-block;
  line-height: 16.8px;
}

#damage .vehicle .name {
  float: right;
  width: 48%;
  padding: 7px 0 0 0;
  text-align: center;
}

#damage .vehicle .name h3 {
  font-size: 22px;
  margin: 0 0 -2px 0;
  padding: 0;
}

#damage .vehicle .name .vin {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 0 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
}

#damage .vehicle .name .vin .line {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 7px;
  background: #dbdee0;
  z-index: -1;
}

#damage .vehicle .name .list {
  position: relative;
  margin: 50px 0 0 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

#damage .vehicle .name .list li {
  display: block;
  position: relative;
  overflow: scroll;
  margin: 0;
  padding: 14px 0;
  border-top: 1px solid #ddd;
}

#damage .vehicle .name .list li.split {
  width: 50%;
  float: left;
}
#damage .vehicle .name .list li:last-child {
  clear: both;
}
#damage .vehicle .name .list li.split:nth-child(2) {
  border-left: 1px solid #ddd;
}

#damage .vehicle .name .list li i {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 12px;
  opacity: 0.4;
}

#damage .vehicle .name .list li h5 {
  font-size: 11px;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0;
}

#damage .vehicle .name .list li p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 0px 0;
  padding: 0;
}

#damage .vehicle .name .list li input {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 0px 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
}

#damage .vehicle .details {
  margin: 0;
  padding: 0;
}

#damage .vehicle .details .list {
  position: relative;
  margin: 30px 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

#damage .vehicle .details .list li {
  float: left;
  display: block;
  position: relative;
  margin: 0;
  width: 33.333%;
  padding: 14px 0;
  text-align: center;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  &:first-child {
    border-left: 1px solid #ddd;
  }
  &:nth-child(4) {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  &:nth-child(5),
  &:nth-child(6) {
    border-bottom: 1px solid #ddd;
  }
  &:last-child {
    border-top: none;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
}

#damage .vehicle .details .list li h5 {
  font-size: 11px;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0;
}

#damage .vehicle .details .list li p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 0px 0;
  padding: 0;
}

#damage .vehicle .details .list li input {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 0px 0;
  width: 100%;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 26.666666667px;
  padding: 6.666666667px;
  width: 133.333333333%;
  transform: scale(0.75);
  transform-origin: left top;
}

#damage .vehicle .details .list li i {
  position: absolute;
  top: 13px;
  right: 13px;
  font-size: 12px;
  opacity: 0.4;
}

#damage .title {
  margin: 0 0 35px 0;
  padding: 0;
  // text-align: center;
}

#damage .title h3 {
  position: relative;
  display: inline-block;
  font-size: 18px;
  margin: 0 0 10px 0;
  text-transform: uppercase;
  padding: 0 5px 0px 5px;
}

#damage .title h3 .line {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 7px;
  background: #27d089;
  opacity: 0.45;
  z-index: -1;
}

#damage .title p {
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 14px;
  color: #7d838e;
}

#damage .photos {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: left;
}

#damage .photos li {
  display: inline-block;
  position: relative;
  width: 48.2%;
  margin: 0 3% 30px 0;
  vertical-align: top;
}

#damage .photos li:nth-child(2n) {
  margin: 0 0 30px 0;
}
#damage .photos li img {
  width: 100%;
  margin: 0 0 0px 0;
}
#damage .photos li .text {
  position: relative;
}

#damage .photos li .description {
  margin: 15px 0 0 0;
  padding: 0;
}

#damage .photos li .item {
  margin: 0px 0 15px 0;
  padding: 0px;
}

#damage .photos li .grade {
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 0;
}

#damage .photos li .grade h5 {
  float: left;
  font-size: 9px;
  font-weight: 600;
  color: #333;
  margin: 8px 10px 0 0;
  padding: 0;
}

#damage .photos li .grade h3 {
  position: relative;
  float: right;
  margin: 0;
  padding: 0 5px;
  font-weight: 600;
}

#damage .photos li .grade h3 .line {
  position: absolute;
  bottom: 2px;
  left: 0px;
  right: 0px;
  height: 8px;
  opacity: 0.45;
  background: #27d089;
  z-index: -1;
}

#damage .photos li .grade h3 .line.orange {
  background: #f27b1a;
}

#damage .photos li h5 {
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 3px 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

#damage .photos li p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#damage .photos li .buttons {
  position: relative;
  overflow: hidden;
}

#damage .photos li .buttons .button {
  float: right;
  border: none;
  position: relative;
  padding: 14px 30px;
  width: 58%;
  margin: 20px auto 0 auto;
  color: #fff;
  background: #27d089;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -webkit-border-radius: 400px;
  -moz-border-radius: 400px;
  border-radius: 400px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

#damage .photos li .buttons .button:nth-child(2) {
  float: left;
  background: rgba(46, 25, 62, 1);
  width: 40%;
}

#damage .photos li .text {
  border: 1px solid #ddd;
  padding: 25px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#damage .buttons .response {
  display: none;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #27d089;
  padding: 10px;
  text-align: center;
  width: 100%;
  margin: 20px 0 0 0;
  background: #f4f5f7;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#damage .buttons .response.deny {
  color: red;
}

#damage .bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-top: 1px solid #ddd;
  padding: 30px 0;
}

#damage .bottom .button {
  position: relative;
  padding: 18px 30px;
  margin: 0px auto;
  color: #fff;
  background: #27d089;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -webkit-border-radius: 400px;
  -moz-border-radius: 400px;
  border-radius: 400px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
.re-damages {
  section#damage {
    .photo,
    .name {
      width: 100% !important;
    }
    ul.photos {
      li.re-item {
        width: 100% !important;
      }
    }
    ul.list.relist {
      li {
        width: 50% !important;
        &:nth-child(3) {
          border-left: 1px solid #ddd;
        }
        &:nth-child(5) {
          border-left: 1px solid #ddd;
        }
        &:nth-child(4) {
          border-left: unset;
          border-bottom: unset;
        }
      }
    }
  }
}

/* --- --- */

// waiting
.ant-skeleton {
  background: #fff;
  height: calc(100vh);
}
.ant-skeleton-content {
  vertical-align: middle;
}
.ant-skeleton.newStyle {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}
.newtyle-loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 999;
  transition: all 0.3s ease;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.dis-hover {
  button {
    &:hover,
    &:focus {
      color: #fff !important;
      background: #27d089 !important;
    }
  }
}
#damage {
  #loopDamage {
    div {
      .list {
        .car-box {
          padding: 15px;
          .tire {
            padding: 0px 5px;
            &:first-child {
              margin-bottom: 15px;
            }

            .edit-damages {
              color: #fff;
              margin-top: 5px;
              padding: 5px 15px;
              background: #27d089;
              font-size: 12px;
              font-weight: bold;
              border-radius: 30px;
              display: inline-block;
              text-transform: uppercase;
              i {
                font-size: 12px;
                margin-right: 5px;
              }
            }
            .title {
              margin-bottom: 2px;
              font-weight: bold;
              // text-align: left;
            }
            .damage {
              font-size: 12px;
              margin-bottom: 5px;
            }
            .des {
              font-size: 12px;
              margin-bottom: 10px;
            }
            .fix {
              font-size: 12px;
            }
          }
          .box {
            border-radius: 25px;
            width: 100%;
            height: 285px;
            border: 5px solid #7d838e;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;
            .img-tire {
              width: 15%;
              position: absolute;
              svg {
                width: 100%;
                color: brown;
              }
              &.front-left {
                top: 10px;
                left: -15px;
              }
              &.rear-left {
                bottom: 10px;
                left: -15px;
              }
              &.front-right {
                top: 10px;
                right: -15px;
              }
              &.rear-right {
                bottom: 10px;
                right: -15px;
              }
              img {
                width: 100%;
              }
            }
          }
        }
        table {
          .ant-table-cell {
            text-transform: capitalize;
          }
          tbody {
            tr {
              td {
                .detail {
                  align-items: center;
                  .number {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    color: #fff;
                  }
                  .name {
                    // padding: 5px;
                    margin-left: 5px;
                    // width: 70%;
                    text-align: left;
                  }
                }
                font-size: 12px;
                .custom-switch-btn {
                  width: 165px;
                  float: left;
                  li {
                    font-size: 8px;
                  }
                }
              }
              td.ant-table-cell {
                padding: 5px !important;
              }
            }
          }
        }
      }
      .detail {
        display: flex;
        // justify-content: space-between;
        .row {
          width: 100%;
        }
        .photo {
          img {
            width: 100%;
          }
          margin-bottom: 10px;
        }
        .info {
          height: 100%;
          .serverity-tire {
            display: block;
            text-align: center;
            height: 100%;
            margin-left: 25px;
            .circle {
              text-align: center;
              display: flex;
              margin-bottom: 20px;
              &:nth-child(1) {
                .ball {
                  background-color: #00b894;
                }
              }
              &:nth-child(2) {
                .ball {
                  background-color: #f9ca24;
                }
              }
              &:nth-child(3) {
                .ball {
                  background-color: #f0932b;
                }
              }
              &:nth-child(4) {
                .ball {
                  background-color: #b71540;
                }
              }
              &:nth-child(5) {
                .ball {
                  background-color: #74b9ff;
                }
              }
              .ball {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                margin-right: 15px;
              }
              .text {
                line-height: 18px;
                margin-bottom: 0;
              }
            }
          }
          .serverity {
            h5 {
              line-height: 25px;
              margin-left: 15px;
            }
            .circle {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              margin-left: 15px;
              &:nth-child(2) {
                background-color: #718093;
              }
              &:nth-child(3) {
                background-color: #f9ca24;
              }
              &:nth-child(4) {
                background-color: #f0932b;
              }
              &:nth-child(5) {
                background-color: #e84118;
              }
            }
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
          }
          .list-damages {
            .damage {
              width: 50%;
              .detail {
                display: flex;
                padding: 4px 8px;
                background: #f27b1a;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 20px;
                line-height: 22px;
                .number {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  background: #fff;
                  font-size: 9px;
                  color: #000000;
                  margin-right: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .name {
                  line-height: 16px;
                  font-size: 8px;
                  color: #000;
                }
              }
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
.paddingForm {
  padding: 0 10px;
}
