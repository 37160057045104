.pave-deeplink {
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: center;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0px;
  min-height: 0px;
  min-width: 0px;
  padding: 0px;
  position: relative;
  z-index: 0;
  background-color: white;
  height: 100vh;
  justify-content: center;

  button {
    border: none;
    background-color: $primary-color-green;
    color: #fff;
    padding: 5px 35px;
    text-transform: uppercase;
    &:focus,
    &:active,
    &:hover {
      background-color: $primary-color-green;
      color: #fff;
    }
  }
  .url {
    margin-bottom: 15px;
    text-align: center;
    span {
      font-size: 26px;
      display: block;
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
  .button {
    text-align: center;
  }
}
