body {
  background: rgba(0, 0, 0, 1);
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  -webkit-font-smoothing: antialiased;

  a {
    text-decoration: none !important;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: -1 !important;
  button {
    &:hover {
      background: inherit;
      color: inherit;
    }
  }

  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 10px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .animated {
    transition: all 0.2s ease;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
  }
  .animatedFadeInUp {
    opacity: 0;
  }
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
  .fadeInDown {
    opacity: 0;
    animation-name: fadeInDown;
    -webkit-animation-name: fadeInDown;
  }
  @keyframes fadeInDown {
    0% {
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .ant-progress-bg {
    height: 6px;
  }
  .ant-progress-outer {
    padding-right: unset !important;
    .ant-progress-inner {
      margin-top: 10px;
    }
  }
  span.ant-progress-text {
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  }
  .error {
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
  }
  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
  .led {
    // border-radius: 50%;
    // background-color: rgb(255, 136, 115);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    -webkit-animation: led 1.5s infinite;
    -moz-animation: led 1.5s infinite;
    -ms-animation: led 1.5s infinite;
    -o-animation: led 1.5s infinite;
    animation: led 1.5s infinite;
  }
  /* keyframes */
  @-webkit-keyframes led {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes led {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  @-ms-keyframes led {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes led {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes led {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  ::-webkit-scrollbar {
    width: 0px !important; /* Remove scrollbar space */
    background: transparent !important; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}
.rebtn {
  padding: 0 !important;
}

#captured-video {
  width: 100%;
  height: 100%;
}
.session_review {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  // background: #000 !important;
  .btn-position {
    display: block;
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 30px;
  }
}
#session_review {
  width: 100%;
  height: 100%;
  position: relative;
  // background: black !important;
}
#Session_start {
  min-height: -webkit-fill-available;
  min-height: calc(100vh);
  .cam-style {
    box-shadow: rgb(0, 0, 0) 0px 0px 192px 20px inset;
    background: rgba(0, 0, 0, 0.17);
    overflow: hidden;
  }
  .camera-video {
    position: fixed;
    top: 0;
    z-index: -3;
    video {
      object-fit: cover;
      // object-position: top left;
      max-width: 100%;
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  .manual-icon {
    position: absolute;
    right: 15px;
    color: #fff;
    top: 45%;
    z-index: 10;
    i {
      font-size: 40px;
    }
  }
  .cam-style-for-qrscan {
    border-style: solid;
    box-sizing: border-box;
    inset: 0px;
    transition: all 0.3s ease;
  }
  .cam-style-for-qrscan-x {
    border-top-width: 76.5px;
    border-right-width: 134.5px;
    border-bottom-width: 38.5px;
    border-left-width: 134.5px;
  }
  .cam-style-for-qrscan-y {
    position: fixed;
    border-top-width: 200.5px;
    border-right-width: 75.5px;
    border-bottom-width: 200.5px;
    border-left-width: 74.5px;
  }
}

.custom-switch-on-vin {
  height: 25px;
  width: 60px;
  background: gray;
  & > .ant-switch-handle {
    top: 3px;
    left: 4px;
  }
  &.ant-switch-checked > .ant-switch-handle {
    left: calc(100% - 22px);
  }
  & > .ant-switch-inner {
    line-height: 25px;
  }
}

.Session_start {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(var(--vh, 1vh) * 100) !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .cam-style {
    .restyle {
      span {
        margin-left: unset !important;
      }
    }
    .relogo {
      position: relative !important;
      top: 0 !important;
      left: -10px !important;
    }
  }
}
.btn-restyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.re-height {
  height: 100vw;
  transform: rotate(90deg);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -65px !important;
  .wrapp-image {
    width: 100% !important;
    max-width: 450px;
  }
}

// .drawCanvas {
//   height: 100%;
//   #captured-video {
//     width: unset;
//   }
// }

// .removeCanvas {
//   width: 100%;
//   #captured-video {
//     width: 100%;
//   }
// }

.landscape-home {
  // height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  // height: calc(var(--vh, 1vh) * 100);
  // height: 100%;
  position: absolute;
  width: 100%;
  overflow-y: scroll;
  background: #fff;
  .underlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 1;
    background: #f2f3f4;
  }
  .bottom-btn {
    position: absolute;
    width: 100%;
    bottom: 0;
    .wrap-btn {
      z-index: 2;
      width: 100%;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 0px 40px;
      position: absolute;
      bottom: 45px;
    }
  }
}

video::-webkit-media-controls-overlay-play-button {
  display: none;
}
.overlay-countdown {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.div_none {
  display: none;
}
.offline-overlay {
  background: rgba(46, 25, 62, 0.8);
  // position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.dev-info {
  .custom-highLight {
    color: #fff;
    box-shadow: 0 0 15px #d35400;
    text-shadow: 0 0 15px #d35400;
    // background: transparent;
  }
}
.session_review_wrapp {
  height: 100vw !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}
#multiUploads {
  padding: 0 15px;
  .ant-upload-list {
    max-height: calc(150px);
    overflow: scroll;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
.colorCustom {
  span {
    color: #27d089;
  }
}
.custom-control-progress {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 0 30px;
  z-index: 1000;
}
.flex-style {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  text-align: center;
  flex-direction: row-reverse;
  div {
    &:first-child {
      // padding: 10px 0;
      button {
        margin-left: 0 !important;
      }
    }
    &:nth-child(2) {
      margin: auto 0;
    }
  }
}
.modalTemp {
  button.ant-modal-close {
    display: none;
  }
}
#video {
  position: relative;
  &::after {
    content: "";
    height: 500px;
    width: 500px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
    box-shadow: rgb(0, 0, 0) 0px 0px 192px 20px inset;
    background: rgba(0, 0, 0, 1);
  }
}
.ant-notification-notice {
  border-radius: 20px;
}

// bootstrap  class custom
.mt-3 {
  margin-top: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.w-100 {
  width: 100% !important;
}
.text-center {
  text-align: center !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.d-flex {
  display: flex !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.w-50 {
  width: 50% !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.25rem;
}

.pt-4 {
  padding-top: 1.5rem !important;
}
.confirm-version-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 25px 10px 15px 10px;
  }
  .ant-modal-confirm-btns {
    float: unset;
    display: flex;
    justify-content: center;
    button {
      border: none;
      color: #fff;
      border-radius: 15px;
      &:nth-child(1) {
        padding: 5px 25px;
        background-color: $primary-color-violet;
      }
      &:nth-child(2) {
        margin-left: 20px;
        padding: 5px 35px;
        background-color: $primary-color-green;
      }
    }
  }
}
.user-add-damages-modal {
  .ant-modal-confirm-btns {
    justify-content: flex-end;
    button {
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
.lazy-image {
  opacity: 0.3;
  transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
}

.lazy-image.opacity {
  opacity: 1;
}
// @media (orientation: landscape) {
//   body {
//     width: 100vh;

//     -webkit-transform: rotate(-90deg);
//     -moz-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//   }
// }
// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     height: 100vw;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }
