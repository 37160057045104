.report {
  height: calc(100vh);
  position: absolute;
  overflow: hidden;
  width: 100%;
  top: 0;
  display: block;
  height: calc(100vh);
  position: absolute;
  overflow: hidden;
  width: calc(100%);
  background: white !important;
}

#btn-switch {
  position: relative;
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
  .action {
    .select {
      padding: 0;
      overflow: hidden;
      position: relative;
      -webkit-border-radius: 500px;
      -moz-border-radius: 500px;
      border-radius: 500px;
      background: #f2f3f4;
      li {
        border: 1px solid transparent;
        display: block;
        float: left;
        width: 33.333%;
        font-size: 11px;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 8px;
        margin: 0;
        color: #9099a8;
        transition: all 0.6s ease-in-out;
        &:first-child.active {
          background: #27d089;
          color: #fff;
          border: 1px solid #27d089;
        }
        &:last-child.active {
          background: rgb(46, 25, 62);
          color: #fff;
          border: 1px solid rgb(46, 25, 62);
        }
      }
      .active {
        transition: all 0.6s ease-in-out;
        background: #fff;
        border: 1px solid #e3e5ea;
        -webkit-border-radius: 500px;
        -moz-border-radius: 500px;
        border-radius: 500px;
      }
    }
  }
}
#sub-menu-report {
  transition: all 0.4s ease;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
  .wrappcontent {
    position: relative;
    height: 100%;
    .positionContent {
      padding: 20px;
      position: absolute;
      bottom: 45px;
      right: 155px;
      .componentBtn {
        .nMutual {
          display: block;
          margin-bottom: 15px;
          -webkit-box-shadow: -12px 20px 77px -41px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: -12px 20px 77px -41px rgba(0, 0, 0, 0.75);
          box-shadow: -12px 20px 77px -41px rgba(0, 0, 0, 0.75);
          .wrappItem {
            display: flex;
            justify-content: flex-end;
            span {
              &:first-child {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 10px;
                margin: auto 12px auto 0;
                white-space: nowrap;
              }
            }
            i {
              background: #27d089;
              color: #fff;
              padding: 8px;
              border-radius: 50%;
            }
          }
          .disable {
            i {
              background: #acb0b7;
            }
          }
        }
      }
    }
  }
}

.uploadpage {
  background: #fff;
  width: 100%;
  min-height: calc(100vh);
  // padding: 60px 30px 60px 30px;
  .wrappForm {
    #uploadForm-report {
      .ant-upload-picture-card-wrapper {
        width: unset;
      }
      .avatar-uploader > .ant-upload {
        width: 128px;
        height: 128px;
      }
      .ant-upload.ant-upload-select-picture-card {
        // margin: 4px;
        border-color: rgba(46, 25, 62, 1);
        &:hover {
          border-color: #27d089;
        }
      }
      .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
      }

      .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
      }
      .ant-upload-list-picture .ant-upload-list-item-error,
      .ant-upload-list-picture-card .ant-upload-list-item-error {
        // border: none;
        border: 1px solid #d9d9d9;
      }
      .ant-upload.ant-upload-select-picture-card > .ant-upload {
        padding: unset;
      }
      .ant-upload-list-picture .ant-upload-list-item,
      .ant-upload-list-picture-card .ant-upload-list-item {
        padding: unset;
        max-width: 112px;
        max-height: 112px;
        width: 100%;
        height: 100%;
      }
      .ant-upload-picture-card-wrapper {
        display: flex;
        justify-content: flex-start;
      }
      .re-style-gridView {
        display: flex;
        span.ant-upload-picture-card-wrapper {
          display: block !important;
        }
        .ant-upload-list-picture-card .ant-upload-list-item-actions {
          opacity: 1 !important;
          display: flex;
          width: 100%;
          height: 100%;
          position: absolute;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.4);
          z-index: 1;
          a {
            pointer-events: unset !important;
            span {
              color: #27d089;
              margin: 0;
              font-size: 18px;
            }
          }
          button {
            display: none;
          }
          .anticon-download,
          .anticon-delete {
            display: none;
          }
        }
      }
      .avatar-thumb {
        .ant-upload.ant-upload-select.ant-upload-select-picture-card {
          max-height: 150px;
          max-width: 150px;
          overflow: hidden;
          display: block;
        }
        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          width: 100%;
          height: 100%;
          padding: 0px;
          text-align: center;
          vertical-align: middle;
          display: flex;
          flex-direction: column;
          margin: auto;
          justify-content: center;
        }
      }
    }
  }
}
.ant-modal-wrap {
  // z-index: 99999999;
  .ant-modal.viewModal {
    top: 0 !important;
    width: calc(100vw) !important;
    height: calc(100vh) !important;
    .ant-modal-content {
      width: 100%;
      img {
        max-width: 100%;
      }
      .ant-modal-body {
        overflow: scroll;
        height: 100vh;
        padding: 0;
        .wrapp-img {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .delete-img {
            position: fixed;
            right: 23px;
            bottom: 23px;
            i {
              color: #27d089;
              font-size: 22px;
            }
          }
        }
      }
    }
    button {
      i {
        color: #27d089;
        &:after {
          content: "";
          border: 1px solid #27d089;
          height: 15px;
          width: 25px;
        }
      }
    }
  }
}

.backBtn {
  padding: 10px 0;
  span.wrappBack {
    font-size: 16px;
    i {
      margin-right: 15px;
      position: relative;
      top: 2px;
      font-size: 22px;
    }
  }
}
.edited-btn {
  button {
    background: $primary-color-green;
    color: #fff;
    border-bottom-left-radius: 15px;
  }
}
// .test {
//   transition: all 0.5s linear;
// }
// .rotate {
//   -moz-transition: all 0.5s linear;
//   -webkit-transition: all 0.5s linear;
//   transition: all 0.5s linear;
// }
// .rotate.down {
//   -moz-transform: rotate(90deg);
//   -webkit-transform: rotate(90deg);
//   transform: rotate(90deg);
//   transition: all 0.5s linear;
// }
