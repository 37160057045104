#vinDecode {
  color: #fff;
  z-index: 102;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background: rgba(0, 0, 0, 1);
  .alignCenter {
    z-index: 6;
    position: relative;
  }
  .alignCenter,
  .vinManual {
    transition: all 0.2s ease;
    height: calc(100vh);
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    .vin-manual {
      max-width: 768px;
      width: 100%;
      transition: all 0.2s ease;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh);
      .wrapp-img {
        max-width: 150px;
        width: 100%;
        margin: 0 auto 20px auto;
        img {
          max-width: 100%;
        }
      }

      .vin-decode-stack,
      .vin-input {
        width: 100%;
        border-radius: 20px;
        transition: box-shadow 0.2s;
        color: gray;
        outline: none;
        text-transform: uppercase;
        padding: 6px 0;
        font-size: 14px;
      }

      .wrap-vin-input {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  .vinManual {
    .wrap-vinManual {
      background: rgba(0, 0, 0, 0.7);
      padding: 40px 100px;
      border-radius: 15px;
      // border: 1px solid #fff;
      .vin-content {
        .vin-icon-manual {
          display: flex;
          justify-content: space-evenly;
          i {
            // border: 1px solid #fff;
            padding: 3px 10px;
            border-radius: 10px;
            font-size: 60px;
            color: #27d089;
          }
          .vin-icon {
            padding: 5px 15px;
            span {
              font-size: 14px;
              margin-bottom: 5px;
              display: block;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    h5 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      &:first-child {
        font-weight: 800;
        letter-spacing: 3px;
      }
      &:last-child {
        font-size: 17px;
      }
    }
  }
  .btn-custom {
    margin-top: 30px;
    // position: fixed;
    // bottom: 30px;
    // left: 0;
    // right: 0;
  }
}

.vin-selection {
  .iconBar {
    margin: 10px;
    background: #121212;
    border-radius: 5px;
    max-width: 130px;
    width: 100%;
    margin: 15px;
    height: max-content;
    // box-shadow: 2px 2px 6px $primary-color-green;
    span {
      font-size: 11px;
      color: $primary-color-gray;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      display: block;
      padding: 0 0 10px 0;
    }
    i {
      text-shadow: 2px 2px 2px $primary-color-green;
    }
  }
  .vin-selection-caption {
    font-size: 13px;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 1.5px;
    color: $primary-color-gray;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 1px 1px 2px $primary-color-green;
    color: white;
  }
}
.offline-btn {
  background-color: gray !important;
  opacity: 0.5;
}
.overlay-bg-blur {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
}
.wrapp-img-custom {
  max-width: 150px;
  width: 100%;
  margin: 20px auto;
  transition: all 0.3s ease;
  position: relative;
  img {
    max-width: 100%;
  }
}
.vinStack-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  // max-width: 488px;
  width: 100%;
  margin: 0 auto;
  .container-item {
    display: flex;
    justify-content: flex-start;
    .item {
      background-color: transparent;
      border: 1px solid gray;
      font-size: 18px;
      text-align: center;
      padding: 15px 11px;
      margin-left: 6px;
      border-radius: 5px;
      &:first-child {
        margin-left: unset;
      }
      &:last-child {
        display: none;
      }
    }
  }
}
.vin-decode-stack {
  // max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-left: 7px !important;
  letter-spacing: 21px;
  border: none !important;
  background: transparent !important;
  overflow: hidden !important;
}
.stack-wrapper {
  .wrapp-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    input {
      max-width: 25px;
      width: 100%;
      transition: box-shadow 0.2s;
      color: gray;
      outline: none;
      border-radius: 5px;
      text-transform: uppercase;
      padding: 6px 0;
      font-size: 16px;
      text-align: center;
      margin-left: 3px;
      margin-bottom: 5px;
      background-color: transparent;
      border: 1px solid gray;
      &:first-child {
        margin-left: unset;
      }
      &:focus {
        border: 1px solid $primary-color-green;
        box-shadow: 0 0 10px $primary-color-green;
      }
    }
  }
}
.vin-mess {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 0 auto 20px auto;
  font-weight: 800;
}
.oops {
  font-weight: 700;
  text-transform: uppercase;
  span {
    font-size: 20px;
    color: #27d089;
  }
}
.activeBtn {
  border: 2px solid #fff;
}
