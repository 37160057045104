#Spinner_icon {
	transition: all 0.1s linear;
	width: 100%;
	height: 60px;
	opacity: 0.5;
	transform: scale(1);
	z-index: -1;
}
#Spinner_icon > div > div {
	animation-play-state: paused;
	border: 5px solid rgba(50, 22, 64, 1);
	border-radius: 50%;
	animation: rotate 1s infinite linear;
	border-left-color: rgba(50, 22, 64, 0.4);
	width: 100%;
	height: 100%;
}
#Spinner_icon > div {
	margin: auto;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	padding: 5px;
}
#Spinner_icon div div {
	animation-play-state: running;
}
@keyframes loadicons {
	0% {
		transform: scale(0) translate3d(0, 0, 0);
		opacity: 0;
	}
	11% {
		transform: scale(1.2) translate3d(0, 0, 0);
		opacity: 1;
	}
	22% {
		transform: scale(1) translate3d(0, 0, 0);
		opacity: 0.5;
	}
	33% {
		transform: scale(0) translate3d(0, 0, 0);
		opacity: 0;
	}
}
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}
#Spinner_icon i {
	font-size: 24px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	color: #321640;
	width: 24px;
	height: 24px;
	line-height: 1;
	transform: scale(0) translate3d(0, 0, 0);
}
#Spinner_icon i:nth-of-type(1) {
	animation: loadicons 5s infinite ease-in-out;
}
#Spinner_icon i:nth-of-type(2) {
	animation: loadicons 5s 1s infinite ease-in-out;
}
#Spinner_icon i:nth-of-type(3) {
	animation: loadicons 5s 2s infinite ease-in-out;
}
#Spinner_icon i:nth-of-type(4) {
	animation: loadicons 5s 3s infinite ease-in-out;
}
#Spinner_icon i:nth-of-type(5) {
	animation: loadicons 5s 4s infinite ease-in-out;
}
