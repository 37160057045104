.navbar-view {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  // background: rgba(102, 51, 153, 0.3);
  transition: all 0.3s ease;

  .wrap-photos {
    // width: 100px;
    .padding {
      .title {
        font-size: 14px;
        text-transform: capitalize;
        padding: 5px 0;
      }
      .content {
        .loop-img {
          // height: calc(100vh - 45px);
          overflow: scroll;
          padding: 5px;

          .img-child {
            width: 85px;
            height: 50px;
            display: block;
            margin-bottom: 5px;
            position: relative;
            .border-wrapp {
              border: 2px solid $primary-color-gray;
              height: 100%;
              display: flex;
              justify-content: center;
              max-width: 100%;
              padding: 5px;
              img {
                display: block;
                width: 100%;
                margin: auto 0;
                max-height: 100%;
                // border-radius: 3px;
                object-fit: cover;
                transition: all 0.5s ease;
                z-index: -2;
              }
              // span {
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   right: 0;
              //   bottom: 0;
              //   margin: auto;
              //   color: #fff;
              //   font-size: 12px;
              //   background: #27d089;
              //   border-radius: 50%;
              //   width: 30px;
              //   height: 30px;
              //   display: block;
              //   line-height: 30px;
              //   font-weight: bold;
              // }
              span {
                position: absolute;
                font-size: 5px;
                width: 10px;
                height: 10px;
                color: #fff;
                // border-radius: 50%;
                line-height: 10px;
                display: block;
                font-weight: bold;
                text-align: center;
              }
              .overflow-img {
                position: absolute;
                bottom: 0;
                width: 100%;
                z-index: -2;
              }
              span.unit {
                top: 2px;
                left: 2px;
                border-radius: 50%;
              }
              span.unit-custom {
                bottom: 2px;
                right: 2px;
                border-top-left-radius: 3px;
              }
              .deactive {
                background: rgba(211, 211, 211, 0.5);
              }
              .active {
                background: #27d089;
              }
              span.flag-unit {
                right: 2px;
                background: red;
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal-wrap {
  overflow: hidden;
}
.modal-pave-pass-viewer {
  position: relative;
  max-height: 130px;
  height: 100%;
  overflow-y: scroll;
}
.customModalReview {
  .ant-modal-content {
    height: calc(var(--vh, 1vh) * 100);
    padding: 25px;
    background: transparent;
    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
    button {
      color: #fff;
      outline: none;
      background: transparent;
      right: 15px;
      top: 33px;
      padding: 5px 10px;
      span {
        //   background: #27d089;
        border-radius: 50%;
        background-color: #27d089;
        padding: 2px;
        width: 40px;
        height: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: #fff;
          align-items: center;
          line-height: 33px;
          font-size: 13px;
          &:after {
            content: "";
            border: 0.6px solid #fff;
            height: 20px;
            width: 20px;
          }
        }
      }
      &:after {
        content: "CLOSE PREVIEW";
        position: absolute;
        bottom: -10px;
        font-size: 8px;
        white-space: nowrap;
        left: -20px;
      }
    }
    .ant-modal-body {
      padding: 0;
      overflow: scroll;
      height: calc(100vh);
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      .wrapp-img {
        // max-width: 90%;
        margin-top: -10px;
        // height: calc(100vh);
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.customModal {
  button {
    display: none;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
    // .btn-simulator {
    // 	span {
    // 		border-radius: 50%;
    // 		background-color: #27d089;
    // 		padding: 2px;
    // 		width: 40px;
    // 		height: 40px;
    // 		color: #fff;
    // 		padding: 3px;
    // 		display: block;
    // 		text-align: center;
    // 		line-height: 30px;
    // 		font-size: 18px;
    // 		position: relative;
    // 		&:after {
    // 			content: "close preview";
    // 			position: absolute;
    // 			bottom: 0px;
    // 			font-size: 8px;
    // 			white-space: nowrap;
    // 			left: -5px;
    // 			font-weight: bold;
    // 			text-transform: uppercase;
    // 		}
    // 	}
    // }
  }
}
.slide {
  position: absolute;
  left: -100px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
#btn_style_photobar {
  .slag-btn-1,
  .slag-btn-2 {
    // display: block;
    position: relative;
    // margin-right: 5px;
    // top: -5px;
    // &:after {
    // 	content: "\002F";
    // 	color: #fff;
    // 	// width: 3px;
    // 	// height: 3px;
    // 	// background: rebeccapurple;
    // 	position: absolute;
    // 	right: -10px;
    // 	bottom: -6px;
    // }
    i {
      font-size: 22px !important;
    }
  }
  .slag-btn-2 {
    position: relative;
    top: 1px;
  }
}
.cutom-switch {
  &:after {
    width: 30px;
    // border-radius: 0 !important;
    background-color: rgb(39, 208, 137) !important;
  }
}
.bgByView {
  span {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
}

.blur-bg {
  // background-color: rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  .ant-modal-content {
    padding: unset !important;
  }
}
.imgSize {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  font-size: 8px !important;
  background-color: $primary-color-green;
  width: 100% !important;
}
.multilangs-right {
  // padding: 20px 10px;
  height: 100vh;
  :first-child {
    text-align: center;
  }
}
.multilangs-right-custom {
  :first-child {
    text-align: right;
  }
}
.logo-center {
  :first-child {
    margin: 0 auto;
  }
}
.video-tuts {
  text-align: center;
}
.carousel-custom-tuts {
  button {
    height: unset !important;
  }
}

.iframe-custom-tuts {
  img {
    object-fit: cover;
  }
}
