.retake-gallery-wrapper {
  max-width: 992px;
  margin: 0 auto;
  padding: 0 24px;
  overflow-y: scroll;
  height: calc(100vh);
  .retake-gallery-sub {
    flex: 1 1 33.3333%;
    padding: 0 10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
    .item-card {
      border-radius: 3px;
      font-size: 12px;
      color: #000;
      margin-top: 10px;
      max-width: 180px;
      width: 100%;
      margin-right: 10px;
      transition: all 0.5s ease-in-out;
      border: 1px solid gray;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #fff;
      }
      .wrapp-card {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        span {
          font-weight: bold;
        }
        p {
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 0;
        }
      }
    }
  }
}
