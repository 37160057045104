#Header {
  z-index: 3;
  width: 100%;
  position: relative;
  text-transform: uppercase;
  padding: 30px;
  .privacy {
    &::after {
      top: 12px;
      left: 0;
      right: 0;
    }
    .select-field {
      font-weight: unset;
      select {
        color: #27d089;
        font-weight: bold;
        text-transform: capitalize;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        font-size: 12px;
        letter-spacing: 3px;
      }
      span {
        color: unset;
      }
    }
  }
  .common {
    position: relative;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 2px;
    color: #fff;
    padding: 0 5px;
    margin: auto 0;
  }
  .logo {
    // max-width: 100px;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
    // & > img {
    //   width: 100%;
    // }
  }
  .restyle {
    flex-direction: column;
  }
  .margin {
    margin: 0 !important;
  }
}
/* --- Policy --- */

.create-snowfall {
  .common {
    position: relative;
    font-weight: 800;
    font-size: 10px;
    letter-spacing: 2px;
    color: #fff;
    padding: 0 5px;
    margin: auto 0;
    z-index: 10;
    span {
      text-transform: uppercase;
      display: block;
      width: 100%;
      &::after {
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        right: 0;
        z-index: -1;
        height: 8px;
        background: #424447;
      }
    }
  }
  .privacy {
    .select-field {
      font-weight: unset;
      select {
        color: #27d089;
        font-weight: bold;
        text-transform: capitalize;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        font-size: 12px;
        letter-spacing: 3px;
      }
      span {
        color: unset;
      }
    }
  }
}
.under-line {
  padding: 0 5px;
  letter-spacing: 1px;
  position: relative;
  top: -5px;
  &::after {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 8px;
    background: #424447;
  }
}
#policy {
  margin: 0;
  padding: 30px 30px 30px 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-x: scroll;
  // –webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  background: #fff;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 900;
    color: initial;
  }
}

#policy .text {
  padding: 0 0 30px 0;
}

#policy .text h2 {
  font-size: 26px;
  margin: 0 0 20px 0;
  font-weight: 800;
  padding: 0;
}

#policy .text h4 {
  font-size: 22px;
  margin: 10px 0;
  font-weight: 800;
  padding: 0;
}

#policy .text p {
  font-size: 14px;
  color: #7d838e;
  margin: 10px 0 0 0;
  padding: 0;
}

#policy .exit {
  position: absolute;
  top: 30px;
  right: 20px;
  background: #e8ebed;
  font-size: 18px;
  text-align: center;
  color: #7c8287;
  padding: 6px 0;
  width: 42px;
  height: 42px;
  transition: all 0.5s ease;
  font-family: "Montserrat", sans-serif;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
}

#policy .exit:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

#policy .text h5 {
  margin: 0 0 5px 0;
  padding: 0;
  letter-spacing: 1px;
  font-size: 11px;
  color: #27d089;
}

.langs-style {
  padding: 3px;
  font-weight: 700;
  font-size: 13px;
  color: $primary-color-gray;
  transition: all 0.5s ease;
}
.langs-active {
  color: $primary-color-violet;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  6.25% {
    /* 3s for fade in */
    opacity: 1;
  }
  26.75% {
    /* roughly 10s for stay as-is */
    opacity: 1;
  }
  33% {
    /* 3s for fade out */
    opacity: 0;
  }
}
