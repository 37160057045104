/* --- warning --- */

#warning,
#confirmComplete,
#swipeScreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000002;
  background: rgba(0, 0, 0, 1);
}
#sms-screen {
  position: relative;
  input {
    padding: 6px !important;
  }
}
#warning,
#confirmComplete,
#sms-screen,
#swipeScreen {
  .text {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100%);
    text-align: center;
    h2 {
      color: #fff;
      margin: 0;
      padding: 0;
      font-size: 22px;
      text-transform: uppercase;
    }
    p {
      font-size: 13px;
      color: #9099a5;
      margin: 5px 0 20px 0;
    }
    i {
      color: #e03c3c;
      font-size: 22px;
      margin: 0 0 20px 0;
    }
    h5 {
      color: #9099a5;
      font-size: 11px;
      margin: 0;
      font-weight: 300;
      letter-spacing: 2px;
      strong {
        font-weight: 900;
        color: #27d089;
      }
    }
    .button {
      position: relative;
      display: inline-block;
      padding: 0 5px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 11px;
      letter-spacing: 1px;
      margin: 10px 0 0 0;
      color: #fff;
      .line {
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        height: 7px;
        background: #424447;
        z-index: -1;
      }
    }
  }
}
#swipeScreen {
  z-index: 1002;
  background: transparent;
  display: flex;
  flex-direction: column;
  .wrapp-swipe {
    position: relative;
    z-index: 2;
    height: calc(100vh);
    .text {
      font-family: "Montserrat", sans-serif;
      position: absolute;
      top: 0;
      color: #fff;
      font-size: 16px;

      p {
        display: block;
      }
      span {
        display: block;
        max-width: 550px;
        width: 100%;
        margin: auto;
        letter-spacing: 1px;
      }
      .style-btn {
        button {
          border: none;
          background: transparent;
          border-radius: 3px;
          outline: none;
          position: relative;
          &:after {
            content: "";
            height: 5px;
            width: 100%;
            bottom: 1px;
            left: 0;
            background: #d2d2d2;
            position: absolute;
          }
        }
      }
    }
  }
}
.custom-retake {
  font-size: 14px;
}

#warning.retake {
  display: block;
  z-index: 99;
}
#warning.retake span {
  font-weight: bold;
  color: #fff;
}
#warning.retake h2 {
  font-size: 18px;
}
.warning {
  display: none !important;
}
#sms-screen {
  z-index: 100;
  .title-sms {
    text-align: center;
    .wrapp-img {
      p {
        margin: 35px auto;
        max-width: 150px;
        img {
          width: 100%;
        }
      }
    }
    span.span-title {
      padding: 0 60px;
      display: block;
      color: #fff;
      font-size: 14px;
    }
  }
  .custom-flags-input {
    width: 100%;
    padding: 25px 0 0 0;
    color: gray;
    button {
      outline: none;
      &::after {
        position: relative;
        top: 5px;
      }
    }
    .flag-select__option {
      margin: unset !important;
    }
    svg.PhoneInputCountryIconImg {
      color: white;
    }
  }
}
*:focus {
  outline: none;
}
