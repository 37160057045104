#waitingProccess {
  z-index: 1000002;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background: rgba(0, 0, 0, 0.9);
  .alignCenter {
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 6;
    position: relative;
    .borderNone {
      width: 100%;
      iframe {
        border: none;
        width: 100%;
      }
    }
  }
}
