#disclosure {
  display: block;
  width: 100%;
  margin: 0;
  padding: 90px 8px 55px 8px;
  position: relative;
  z-index: 10;
  background: #fff;
  color: black;
  height: auto;
}

#disclosure .section {
  position: relative;
  overflow: hidden;
}

// disclosure class

.disclosure {
  padding-top: 25px !important;
  margin-top: 100px !important;

  .item {
    .action.selector {
      position: relative !important;
      margin: 20px auto 10px auto !important;
    }
  }
}
.is-landscape {
  .ant-collapse-content-box {padding-left: 50px}
}
.re-control {
  // position: relative !important;
  background: transparent;
  // padding: 0 !important;
  display: flex !important;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    margin-left: 0 !important;
  }
}
.mualtual-style {
  h3 {
    position: relative;
    &:before {
      background: rgba(0, 0, 0, 0.1);
      content: "";
      height: 1px;
      position: absolute;
      width: 80px;
      bottom: -3px;
    }
    &:after {
      background: rgba(0, 0, 0, 0.1);
      content: "";
      height: 1px;
      position: absolute;
      width: 90px;
      bottom: -8px;
      left: 15px;
    }
  }
}
.re-disclosure,
.re-announcements {
  min-height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  // height: calc(var(--vh, 1vh) * 100);
  background: #fff;
}

.style-date-time {
  width: 100%;
  input {
    width: 100%;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.re-disclosure {
  .action .select li:last-child.active {
    background: #27d089 !important;
    color: #fff;
    border: 1px solid #27d089 !important;
  }
  .section .action .select li:first-child.active {
    background: #f22929 !important;
    color: #fff;
    border: 1px solid #f22929 !important;
  }
}
#gallery {
  img {
    width: 100%;
  }
}

.back-to-top-custom {
  position: unset;
  & > div {
    &:first-child {
      width: 40px;
      height: 40px;
      background: rgb(39, 208, 137);
      color: #fff;
      line-height: 3;
      position: unset;
      font-weight: bold;
      margin-left: 15px;
      text-align: center;
      border-radius: 30px;
    }
  }
}
.btt-reportDamages,
.btt-disclosures,
.btt-anouncement {
  & > div {
    &:first-child {
      margin-left: 0px;
    }
  }
  margin-left: 20px;
}
// .btt-disclosures {
// }

.control-progress-rewrite {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 25px 15px;
  justify-content: flex-end;
  .left-btn-custom {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.report-damages {
  padding: 5px 0;
  width: 100%;
  max-width: 500px;
  float: right;
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(131, 127, 127, 0.01),
    rgba(131, 127, 127, 0.2),
    rgba(131, 127, 127, 0.501),
    transparent
  );
}

.dna-multual-viewer {
  background-color: #fff;
  .ant-select-selector {
    &:hover {
      border-color: $primary-color-green-rgba !important;
      box-shadow: none !important;
    }
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-color-green-rgba !important;
    box-shadow: none !important;
  }

  .dna-header {
    h3 {
      position: relative;
      display: inline-block;
      font-size: 20px;
      margin: 0 0 15px 0;
      text-transform: uppercase;
      padding: 0 5px 0px 5px;
      font-weight: 900;
    }
    .line {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 7px;
      background: #27d089;
      opacity: 0.45;
    }
  }
  .content-for-sub {
    &:last-child {
      border-bottom: unset !important;
    }
  }
  .ant-collapse-borderless {
    background-color: transparent;
  }
  .ant-collapse-header {
    font-weight: 800;
    letter-spacing: 1px;
    opacity: 0.9;
    text-transform: uppercase;
    background-color: #f9fafc;
  }

  .radio-content,
  .select-content,
  .switch-toggle-content {
    border-bottom: 1px solid #ddd;
    .ant-row.ant-form-item {
      margin-bottom: 16px;
    }
    margin-bottom: 10px;
  }

  .select-content {
    margin-top: 10px;
    border-bottom: unset;
    margin-bottom: 24px;
  }
  .sub-select-content {
    .ant-select-selector {
      border-radius: 5px;
    }
  }
  span.ant-select-selection-item {
    &::first-letter {
      text-transform: capitalize;
    }
  }
  .ant-input-number {
    &:hover,
    &:focus,
    &:active {
      border-color: $primary-color-green;
    }
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $primary-color-green;
    &:after {
      border: 1px solid $primary-color-green;
      background-color: $primary-color-green;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary-color-green !important;
  }
  .ant-radio-checked {
    &::after {
      border: 1px solid $primary-color-green !important;
    }
  }
  .ant-radio-inner {
    &::after {
      background-color: $primary-color-green;
    }
  }
  .switch-toggle-content {
    margin-top: 10px;
    .ant-radio-button-wrapper-checked {
      border-radius: 20px;
      color: #fff !important;
      outline: unset;
      box-shadow: unset;
      border-color: transparent !important;
      &:first-child {
        background-color: $primary-color-green;
        &:before {
          background-color: $primary-color-green !important;
        }
      }
      &:nth-child(2) {
        background-color: $primary-color-gray;
        &:before {
          background-color: $primary-color-gray !important;
        }
      }
      &:last-child {
        background-color: red;
        &:before {
          background-color: red;
        }
      }
    }

    .switch-item {
      font-size: 12px;
    }
    label.ant-radio-button-wrapper {
      border: none;
      background-color: transparent;
      font-size: 9px;
      // font-family: "Montserrat", sans-serif;
      font-weight: 700;
      letter-spacing: 1px;
      padding: 0px 20px;
      &:before {
        opacity: 0 !important;
      }
    }
    .ant-radio-group,
    .ant-radio-group-outline {
      background-color: transparent;
      border: 1px solid #f2f3f4;
      border-radius: 20px;
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .custom-button-report {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .btn-dna-custom {
    button {
      outline: none;
      border: none;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      padding: 5px 32px;
      height: 40px;
    }
  }
  .main-content {
    border-bottom: 1px solid #ddd;
  }
  .ant-input {
    &:hover,
    &:focus {
      border-color: $primary-color-green;
      box-shadow: unset;
    }
  }
}

.list-input-number-tire {
  .ant-col.ant-form-item-label {
    text-transform: capitalize;
  }
  .ant-col.ant-col-16.ant-form-item-control {
    margin: 0 auto;
  }
  input {
    border-radius: 5px;
    text-align: center;
  }
  .ant-input[disabled] {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-input-affix-wrapper-disabled {
    background-color: transparent;
  }
}
.ant-collapse {
  background: transparent !important;
  text-align: center;
  .ant-collapse > .ant-collapse-item {
    border-bottom: unset !important;
  }
}
.tire-size-modal {
  .ant-modal-title {
    text-transform: capitalize;
  }
  .sub-select-content {
    .ant-select-selector {
      border-radius: 5px;
    }
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $primary-color-green;
    box-shadow: unset !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-color-green;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color-green;
    border-color: $primary-color-green;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $primary-color-green;
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $primary-color-green;
    box-shadow: unset !important;
  }
  .switch-tire-size {
    button {
      &:hover {
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-switch-checked {
    background-color: $primary-color-green !important;
  }
}
.btn-html-submit {
  .btn-dna-custom {
    margin-top: 10px;
    margin-bottom: 0;
    button {
      border-radius: 20px;
      outline: none;
      border: none;
      color: #fff;
      padding: 5px 28px;
    }
    &:first-child {
      margin-right: 15px;
    }
  }
}

.p-toggle {
  display: none !important;
  transition: all 0.5s ease;
}
.action-toggle {
  max-height: 0 !important;
}
.node-toggle {
  max-height: 0 !important;
  border-top: 0 !important;
}
