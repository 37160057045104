.guideLine-home {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  .ant-modal-content {
    background: transparent;
    button {
      display: none;
    }
    .ant-modal-body {
      span.title-ins {
        display: block;
        // text-align: left;
        font-weight: 600;
        height: 40px;
        color: #fff;
      }
      button {
        display: block !important ;
      }
      .body-tuts {
        height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
        .slider_tuts {
          .item-tuts {
            // height: 100px;
            // width: 100px;
            .wrapp-img {
              //   max-width: 250px;
              width: 100%;
              //   text-align: center;
              margin: 0 auto;
              img {
                border-radius: 15px;
                width: 100%;
              }
            }
          }
        }
        .fix-btn {
          position: fixed;
          bottom: 15px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          button {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
// .ant-carousel .slick-dots li button {
// 	//   background: rgba(46, 25, 62, 0.7);
// }
// .ant-carousel .slick-dots li.slick-active {
// 	button {
// 		// background: rgba(46, 25, 62, 0.7);
// 	}
// }

.snowfall {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  .snowflake {
    position: absolute; // [1] Break the layout flow
    // &:after {
    // 	display: block;
    // 	opacity: 0.5;
    // 	content: "\2744"; // [3] Give it some shape
    // }
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.col-style {
  background-image: linear-gradient(to bottom, #fbf9ef, #ffffff);
  padding: 10px;
  display: flex;
  flex-direction: column;
}
// Total numner of snowflakes
$snowflake: 34;

// Randomize the animation and positioning for each snowflake
@for $i from 1 through $snowflake {
  // Position of the snowflake on the y-axis
  $top: (random(50) + 50) * 1%;

  // Position of the snowflake on the x-axis
  $left: random(100) * 1%;

  // Animation delay for the flake
  $delay: random(20) - 1s;

  // Floating span for the flake
  $duration: random(10) + 4s;

  // Size of the flake
  $size: random(24);

  /* Snowflake ##{$i} */
  .snowflake:nth-of-type(#{$i}) {
    animation-name: snowflake-#{$i};
    animation-delay: $delay;

    // Play the animation for anything between 5-10 seconds
    animation-duration: $duration;
    animation-iteration-count: infinite;
    left: $left;
    top: -$top;
    &:after {
      font-size: $size * 1px;
    }
  }

  // Animation for snowflake ##{$i}
  @keyframes snowflake-#{$i} {
    0% {
      transform: rotate(0deg);
      left: $left;
      top: -$top;
    }
    25% {
      left: $left + 1%;
    }

    50% {
      left: $left;
    }

    75% {
      left: $left + 2%;
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      top: $top + 40%;
      opacity: 0;
    }
  }
}
