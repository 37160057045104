/* --- Review --- */

#review {
  position: relative;
  z-index: 199;
  padding: 76px 0 0 0;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#review .notice {
  text-align: center;
  padding: 20px;
  margin: 0 0 60px 0;
  border: 2px solid #e5e8ea;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#review .notice h5 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #777e89;
}

#review .top {
  display: none;
  padding: 15px;
  width: 100%;
}

#review .top .logo {
  position: relative;
  width: 64px;
  z-index: 5;
  margin: 0px;
}

#review .top .logo img {
  width: 100%;
}

#review .top .status {
  position: absolute;
  top: 21px;
  right: 20px;
  font-size: 9px;
  padding: 5px 10px;
  background: #27d089;
  z-index: 9;
  letter-spacing: 1px;
  color: #fff;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}

#review .top .status i {
  padding: 0 2px 0 0;
}

#review .top .status .text {
  position: absolute;
  top: 4.5px;
  left: -73px;
  color: #9099a8;
  padding: 0 15px 0 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  border-right: 1px solid #eee;
}

#review .photo {
  position: relative;
  width: 50%;
  float: left;
  margin: 0 0 0px 0;
  padding: 0 0 0 20px;
  z-index: 4;
  overflow: hidden;
}

#review .photo img {
  width: 100%;
}

#review .photo .background {
  position: absolute;
  left: 0%;
  right: 0;
  background: #f2f3f4;
  bottom: 0;
  z-index: -1;
  top: 100px;
}

#review .photo .overlay {
  position: absolute;
  top: 0;
  left: 20px;
  right: 0;
  bottom: 0;
  z-index: 3;
  -webkit-box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 207px -8px rgba(0, 0, 0, 0.6);
}

#review .photo .count {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 5px 7px;
  font-size: 11px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  z-index: 10;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.7);
}

#review .photo .count i {
  padding: 0 1.5px 0 0;
  font-size: 10px;
  vertical-align: top;
  opacity: 0.7;
  display: inline-block;
  line-height: 16.8px;
}

#review .title {
  position: relative;
  padding: 15px 25px 25px 25px;
  margin: 0 0 0px 0;
  z-index: 4;
  text-align: center;
  overflow: hidden;
}

#review .title .overall {
  position: absolute;
  padding: 40px 40px;
  width: 200px;
  right: 50%;
  top: 195px;
  overflow: hidden;
  margin: 0px -100px 0 0;
  z-index: 9;
  text-align: center;
}

#review .title .overall img {
  width: 80%;
}

#review .title .drop {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 18px;
  transition: all 0.15s ease;
  opacity: 0.4;
}

#review .title .drop.active {
  transform: rotate(180deg);
  transition: all 0.5s ease;
  top: 22px;
}

#review .title h3 {
  font-size: 20px;
  margin: 0 0 -2px 0;
  padding: 0;
}

#review .title .vin {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 0 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
}

#review .title .vin .line {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 7px;
  background: #f2f3f4;
  z-index: -1;
}

#review .title .vehicle {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#review .title .vehicle .list {
  position: relative;
  overflow: hidden;
  margin: 25px 0 0 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

#review .title .vehicle .list li {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 14px 0;
  border-top: 1px solid #ddd;
}

#review .title .vehicle .list li.split {
  width: 50%;
  float: left;
}
#review .title .vehicle .list li.split:last-child {
  border-left: 1px solid #ddd;
}

#review .title .vehicle .list li h5 {
  font-size: 11px;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0;
}

#review .title .vehicle .list li p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 0 0 0px 0;
  padding: 0;
}

#review .title .time {
  display: none;
  position: absolute;
  margin: 0;
  right: 0;
  bottom: 0px;
  padding: 15px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  background: #f2f3f4;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  color: #9099a8;
  z-index: 9;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
}

#review .title .time i {
  position: absolute;
  top: 17px;
  left: 15px;
}

#review .title .background {
  position: absolute;
  left: 0%;
  right: 0;
  background: #f2f3f4;
  bottom: -20px;
  top: 280px;
  z-index: -2;
}

#review .condition {
  display: none;
  position: relative;
  padding: 20px 20px 0 20px;
  background: #f2f3f4;
  z-index: 5;
}

#review .condition .button {
  position: relative;
  padding: 17px 30px;
  color: #fff;
  background: #27d089;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -webkit-border-radius: 400px;
  -moz-border-radius: 400px;
  border-radius: 400px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  -moz-box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  transition: all 0.5s ease;
}

#review .condition .button i {
  position: absolute;
  top: 17px;
  font-size: 15px;
  left: 23px;
}

#review .details {
  position: relative;
  margin: -25px 0 0 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

#review .details .navigation {
  position: relative;
  margin: 0;
  padding: 24px 20px 0 20px;
  overflow: hidden;
  background: #f2f3f4;
}

#review .details .navigation li {
  display: block;
  float: left;
  margin: 0;
  width: 50%;
  padding: 15px 22px;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: #9099a8;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  background: #edeff1;
}

#review .details .navigation li.active {
  background: #fff;
  color: #222;
}

#review .details .section {
  display: none;
  padding: 30px;
}

#review .details .section.inspection {
  display: block;
  margin: 0 0 20px 0;
}

#review .details .section .overall h5 {
  float: left;
  font-size: 12px;
  margin: 0px 10px 0 0;
  padding: 0;
  font-weight: 600;
}

#review .details .section .overall h3 {
  position: relative;
  float: right;
  overflow: hidden;
  margin: 0;
  line-height: 28px;
  padding: 0 5px;
  font-size: 32px;
  font-weight: 600;
}

#review .details .section .overall h3 .line {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 8px;
  opacity: 0.45;
  background: #27d089;
  z-index: -1;
}

#review .details .section .list {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#review .details .section .list li {
  position: relative;
  display: block;
  float: left;
  width: 48.5%;
  margin: 0px 3% 3% 0;
  padding: 20px;
  overflow: hidden;
  border-top: 1px solid #eaedf2;
  border-left: 1px solid #eaedf2;
  border-right: 1px solid #eaedf2;
  border-bottom: 1px solid #eaedf2;
}

#review .details .section .list li:nth-child(2n) {
  margin: 0 0 3% 0;
}

#review .details .section .list li .item {
  margin: 15px 0 0 0;
  padding: 0;
}

#review .details .section .list li .item:nth-child(2) {
  margin: 0;
}

#review .details .section .list li .description {
  margin: 15px 0 0 0;
  padding: 0;
}

#review .details .section .list li h5 {
  font-size: 10px;
  font-weight: 500;
  margin: 0 0 3px 0;
  color: #7e8289;
  letter-spacing: 0.5px;
}

#review .details .section .list li p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#review .details .section .list li .grade {
  position: absolute;
  top: 15px;
  right: 22px;
  padding: 0;
  overflow: hidden;
}

#review .details .section .list li .grade h5 {
  float: left;
  font-size: 9px;
  font-weight: 600;
  color: #333;
  margin: 8px 10px 0 0;
  padding: 0;
}

#review .details .section .list li .grade h3 {
  position: relative;
  float: right;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  font-weight: 600;
}

#review .details .section .list li .grade h3 .line {
  position: absolute;
  bottom: 2px;
  left: 0px;
  right: 0px;
  height: 8px;
  opacity: 0.45;
  background: #27d089;
  z-index: -1;
}

#review .details .section .list li .grade h3 .line.orange {
  background: #f27b1a;
}

#review .details .section .title {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#review .details .section .title .filter {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  background: #f2f3f4;
}

#review .details .section .title .filter li {
  display: block;
  float: left;
  width: 37.5%;
  font-size: 9px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 10px;
  margin: 0;
  color: #9099a8;
}

#review .details .section .title .filter li:first-child {
  width: 25%;
}

#review .details .section .title .filter li.active {
  background: #fff;
  border: 1px solid #e3e5ea;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  padding: 10px 10px;
}

#review .details .section .photos {
  margin: 30px 0 0 0;
  padding: 0;
  overflow: hidden;
}

#review .details .section .photos li {
  display: block;
  float: left;
  width: 48.5%;
  margin: 0 3% 3% 0;
}

#review .details .section .photos li:nth-child(2n) {
  margin: 0 0 3% 0;
}
#review .details .section .photos li img {
  width: 100%;
}

#review .location {
  position: relative;
  margin: 0 30px 30px 30px;
  padding: 0 0 30px 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dee1e8;
}

#review .location .background {
  position: absolute;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: 40px;
  background: #f2f3f4;
  z-index: -1;
}

#review .location .map {
  width: 100%;
  margin: 0 0 -7px 0;
  padding: 0;
  overflow: hidden;
}

#review .location .map iframe {
  width: 100%;
}

#review .location .text {
  padding: 20px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e5e8ea;
}

#review .location .text h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  padding: 0;
}

#review .location .text p {
  font-size: 11px;
  margin: 0;
  padding: 0;
  color: #889099;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

#review .page {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
}

#review .page img {
  width: 220px;
  margin: 0 auto;
}

#review .page .button {
  position: relative;
  padding: 18px 34px 18px 44px;
  color: #fff;
  background: #27d089;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -webkit-border-radius: 400px;
  -moz-border-radius: 400px;
  border-radius: 400px;
  text-align: center;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: -20px auto 0 auto;
  -webkit-box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  -moz-box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  box-shadow: 0px 10px 27px -6px rgba(41, 42, 48, 0.28);
  transition: all 0.5s ease;
}

#review .page .button:hover {
  background: #2dd88e;
}

#review .page .button i {
  position: absolute;
  top: 20.5px;
  font-size: 14px;
  left: 27px;
}
.report-edited {
  border: none;
  font-size: 12px;
  font-weight: 500;
  appearance: none;
  text-align: center;
  background: transparent;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  font-family: "Montserrat", sans-serif;
}
