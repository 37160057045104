/* --- Loading --- */

#loader {
  background: transparent;
  position: relative;
  z-index: 2;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #fff !important;
  }
  span.ant-progress-text {
    color: white;
  }
}

#loader .loading {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 420px;
  height: 60px;
  overflow: hidden;
  margin: -20px 0 0 -210px;
}
#loader .custom-loading {
  top: 80% !important;
  overflow: auto;
  margin: unset;
  padding: 0 30px;
  width: 100%;
  left: 0;
  height: auto;
}

#loader .loading iframe {
  // position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
}

#loader .text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  margin: -70px 0 0 -150px;
  text-align: center;
}

#loader .text h2 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 22px;
  text-transform: uppercase;
}

#loader .text p {
  font-size: 13px;
  color: #9099a5;
  margin: 5px 0 20px 0;
}

#loader .text h5 {
  color: #9099a5;
  font-size: 11px;
  margin: 0;
  font-weight: 300;
  letter-spacing: 2px;
}

#loader .text h5 strong {
  font-weight: 900;
  color: #27d089;
}

#loader .text i {
  color: #27d089;
  font-size: 22px;
  margin: 0 0 20px 0;
}

#loader .loader-text {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 300px;
  margin: 10px 0 0 -150px;
  text-align: center;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  opacity: 0.75;
}
#loader {
  .pave-logo-ddc {
    display: flex;
    margin: auto;
    height: calc(100vh);
    position: relative;
    // top: -40px;
    .wrapp-ddc-component {
      width: 100%;
      margin: auto;
      .wrapp-img-ddc {
        max-width: 250px;
        margin: 20px auto 10px auto;
      }
      .btn-ddc-style {
        margin: 50px auto 10px auto;
        max-width: 480px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        .ddc-style {
          margin-left: 35px;
          text-transform: uppercase;
          border: 2px solid #fff;
          padding: 20px 30px;
          box-shadow: 0 0 15px #27d089;
          text-shadow: 0 0 15px #27d089;
          // width: 100px;
          // height: 100px;
          transition: 0.6s opacity ease-in-out;
          border-radius: 3px;
          &:hover {
            opacity: 1;
            transition: 0.6s opacity ease-in-out;
            // filter: blur(1px);
            // background: #27d089;
            background: linear-gradient(
              60deg,
              #27d089,
              #27d089,
              #ef4e7b,
              #a166ab,
              #5073b8,
              #1098ad,
              #07b39b,
              #6fba82
            );
            span {
              color: #fff;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:nth-child(2) {
            padding: 20px 38px;
          }
          span {
            // line-height: 50px;
            font-weight: bold;
            color: #27d089;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .btn-style {
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 750px;
    z-index: 11;
  }
}

.pulse {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin staggered_transitions($nth: 1, $items: 1, $initial: 0, $step: 0) {
  @for $i from $nth through $items {
    &:nth-of-type(#{$i}) {
      animation-delay: #{$initial}s;
    }
    $initial: $initial + $step;
  }
}

.fadeIn {
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 1s;

  @include staggered_transitions($nth: 1, $items: 5, $initial: 1, $step: 0.5);
}

.animate-bottom {
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 0.5s;
  animation-name: animatebottom;
  animation-duration: 0.5s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -75px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -75px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

.animation-show-subject {
  animation: showtext 1s ease-in-out;
}
@keyframes showtext {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation-top-to-bottom {
  position: relative;
  animation: topTobottom 5s infinite forwards cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

@keyframes topTobottom {
  0% {
    top: -25px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 35px;
  }
}

.animation-right-to-left-photos-offline {
  position: relative;
  animation: rightToLeft_photos 8s infinite forwards
    cubic-bezier(0.5, 0.5, 0.5, 0.5);
  // animation-delay: 2s;
}

@keyframes rightToLeft_photos {
  0% {
    right: -150px;
  }
  75% {
    right: 335px;
  }
  100% {
    right: 335px;
  }
}
.animation-right-to-left-homepage {
  position: relative;
  animation: rightToLeft_hompage 8s infinite forwards
    cubic-bezier(0.5, 0.5, 0.5, 0.5);
  // animation-delay: 2s;
}

@keyframes rightToLeft_hompage {
  0% {
    right: -250px;
  }
  75% {
    right: 550px;
  }
  100% {
    right: 550px;
  }
}

.animation-right-to-left {
  position: relative;
  animation: rightToLeft 10s infinite forwards cubic-bezier(0.5, 0.5, 0.5, 0.5);
  // animation-delay: 2s;
}

@keyframes rightToLeft {
  0% {
    right: -150px;
  }
  75% {
    right: 260px;
  }
  100% {
    right: 265px;
  }
}
.animation-left-to-right {
  position: relative;
  animation: leftToright 1s ease-in;
  // animation-delay: 2s;
}

@keyframes leftToright {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.animation-bottom-to-center {
  position: relative;
  animation: bottomTocenter 0.5s ease-in;
  z-index: 201;
}

@keyframes bottomTocenter {
  0% {
    bottom: -50px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes scanning {
  0%,
  100% {
    left: 90px;
  }

  50% {
    left: 630px;
  }
}
@keyframes scanning-portrait {
  0%,
  100% {
    top: 100px;
  }

  50% {
    top: 500px;
  }
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
}
.img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 1;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
